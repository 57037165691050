<template>
  <v-app style="background: #555">
    <v-main>
      <v-container fill-height>
        <v-layout justify-center align-center>
          <router-view />
        </v-layout>
      </v-container>
    </v-main>
    <portal-target name="v-app" multiple />
  </v-app>
</template>
<
<script>
import { notificationBus } from "@/libs/notificationBus";
export default {
  mounted() {
    // Слушать уведомления о новой версии
    notificationBus.$on("showInfo", this.handleShowInfo);
  },
  beforeDestroy() {
    notificationBus.$off("showInfo", this.handleShowInfo);
  },
  methods: {
    async handleShowInfo(info) {
      this.$root.$emit("show-info", info);
    },
  },
};
</script>

<style lang="scss" scoped></style>
